.login{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    .inputContainer{
        gap: 0 !important;
    }
    &_logo{
        top: 0;
        position: absolute;
        margin: 0 auto;
        margin-top: 33px;
    }
    &__container{
        max-width: 435px;
        &-header{
            margin-bottom: 45px;
            .title{
                font-weight: 700;
                font-size: 35px;
                line-height: 35px;
                margin-bottom: 12px;
            }
            .description{
                color: #858585;
                font-weight: 400;
                font-size: 16px;
                line-height: 25px;
                text-align: center;
            }
        }
        .button{
            padding-top: 10px;
            position: relative;
            .error{
                font-weight: 400;
                font-size: 12px;
                color: #FF613D;
                top: -13px;
                height: fit-content;
                left: 0;
            }
            button{
                width: 100%;
                background: #4955C0;
                border: 1px solid #E8E8E8;
                border-radius: 8px;
                padding: 10.5px ;
                font-weight: 500;
                font-size: 19px;
                font-family: inherit;
                color: white;
                cursor: pointer;
            }
        }
    }
}