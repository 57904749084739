.library__content-upload{
    position: relative;
        &-back{
            top: -17px;
            left: -10px;
            position: absolute;
            cursor: pointer;
            width: 25px;
        }
    .image_data{
        flex-direction: column;
        input{
            display: block;
            height: 40px !important;
        }
        select{
            height: 40px !important; 
        }
        label{
            margin-bottom: 4px !important;
            font-size: 14px !important;
        }
        .button-form{
            padding: 0;
            button{
                padding: 11.5px;
            }
        }
        &-uploaded{
            margin-bottom: 10px;
            width: 350px;
            height: 250px;
            border-radius: 8px;
        }
    }
}