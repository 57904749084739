.library__content-upload{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    input{
        display: none;
    }
    &-place{
        cursor: pointer;
        width: 500px;
        height: 233px;
        padding: 16px;
        border: 1px solid #E4E7EC;
        border-radius: 12px;
        &-icon{
            text-align: center;
        }
        &-description{
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            color: #475467;   
            width: 80%;   
            margin: 0 auto;      
            span{
                color: #5D67C2;
            }    
        }
        .submit-button{
            background: #4955C0;
            border: 1px solid #4955C0;
            border-radius: 8px;      
            padding: 10px 50px;
            font-weight: 500;
            font-size: 16px;
            color: white;  
            cursor: pointer;    
            margin: 0 auto;
            display: block;
            margin-top: 15px;
            width: fit-content;
        }
    }
}