.oneCorrectChoice{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    .answer{
        padding: 15px;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        box-shadow: 0px 3px 0px #E8E8E8;
        border-radius: 10px;
        text-align: center;
        &_content{
            font-weight: 500;
            font-size: 15px;
        }
        cursor: pointer;
    }
}