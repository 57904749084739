.levelItem{
    width: 260px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    z-index: 1;
    cursor: pointer;
    position: relative;
    &-screen{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    transition: all 0.1s;
    &:hover{
        background-color: #dedede2a;
    }
    p{
        font-weight: 600;
        font-size: 20px;
    }
    span{
        position: absolute;
        top: 8px;
        left: 5px;
        z-index: 10;
        img{
            transform: rotate(90deg);
        }
        .more-container{
            left: 15px;
            bottom: -8px;
        }
    }
}