.sidebar__content-language{
    position: absolute;
    bottom: 20px;
    width: 100%;
    border: 1px solid #DEDEDE;
    background-color: white;
    border-radius: 14px;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    &-add , &-setting{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        p{
            font-weight: 500;
            font-size: 13px;
            color: #78787A;
        }
        cursor: pointer;
        border-radius: 8px;
        &:hover{
            background-color: rgba(238, 238, 238, 0.503);
        }
    }
    &-default{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        .sidebar__content-language-country{
            &:hover{
                background-color: transparent;
            }
        }
    }
        &-icon{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-country-content{
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            height: 168px;
            overflow-y: scroll;
            direction: rtl;
            &::-webkit-scrollbar {
                width: 3px;
                background-color: rgb(235, 235, 235);
                border-radius: 20px;
         
              }
            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                border-radius: 20px;
              }
        }

        &-country{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 10px;
            direction: ltr;
            padding: 5px;
            border-radius: 8px;
            &:hover{
                background-color: rgba(238, 238, 238, 0.503);
            }
            cursor: pointer;
            .country-flag{
                display: flex;align-items: center;
                width: 31px;
                height: 31px;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .country-name{
                p{
                    font-weight: 700;
                    font-size: 16px;
                }
            }

        }
}