.phraseBuilderBlock{
    margin-top: 5px ;
    &-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 50px;
        &-sentences{
            width: 100%;
            height: 180px;
            border: 1px solid #DEDEDE;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            color: #949494;
        }
        &_words{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap:  10px;
            justify-content: center;
            .word{
                padding: 10px;
                background: #FFFFFF;
                border: 1px solid #E8E8E8;
                box-shadow: 0px 3px 0px #E8E8E8;
                border-radius: 10px;
                font-weight: 500;
                font-size: 17px;   
                cursor: pointer; 
            }
        }
    }
}