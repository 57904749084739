.translateBlock{

    .text2{
        font-weight: 400;
        font-size: 17px;
        color: #59606D;
        text-align: center;
    }
    &_translate{
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        &-content{
            border: 1px solid #DEDEDE;
            border-radius: 8px;
            .texts{
            padding: 10px 20px;
            }
            &-text{
                text-align: right;
                border-bottom: 1px solid #DEDEDE ;
            }
            &-translate{
                text-align: left;
            }
        }
       
    }
}