.customInput{
    select {
        width: 100%;
        height: 60px;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        padding: 10px;
        font-family: inherit;
        &::-ms-expand {
            display: none;
            }
        &::placeholder{
            font-weight: 400;
            font-size: 15px;
            color: #C9C9C9;
        }
        &::part(listbox) {
            padding: 10px;
            margin-top: 5px;
            border: 1px solid red;
            border-radius: 5px;
          }
      }
    //  .selector-options {
    //     list-style: none;
    //     padding: 5px 0;
    //     margin: 0;
    //     background: #11436b;
    //     color: #fff;
    //     border-radius: 4px;
    //     z-index: 1;
    //     width: 96%;
    //     position: absolute;
    //     left: 2%;
    //     top: 35%;
    //   }
      
      .selector-options li {
        height: 35px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        cursor: pointer;
        transition:  0.3s ease;
      }
      
       .selector-options li:hover {
        background: #03A9F4;
      }
      select:focus {
        outline: none;
      }
}