
  .label {
    display: block;
    text-align: left;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  
  .inputContainer {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    &--add{
      display: flex;
      column-gap: 10px;
      width: 100%;
      position: relative;
    }
  }
  
  .inputContainer input {
    width: 100%;
    height: 50px;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 10px;
    font-family: inherit;
    &::placeholder{
        font-weight: 400;
        font-size: 15px;
        color: #C9C9C9;
    }
  }
  
  .inputContainer input:focus {
    outline: none;
  }
  
  .is-invalid {
    border-color: red  !important;
  }
  
  .error {
    position: absolute;
    font-size: 10px;
    color: red;
    bottom: -17px;
    height: fit-content;
    left: 4px;
  }

  