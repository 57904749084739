@forward "./components/index";
.data-container{
    height: calc(100% - 69px);
    &-content{
        margin: 20px;
        height: calc(100% - 197px);
        &-table{
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100% - 28px);
            &::-webkit-scrollbar {
                width: 3px;
                background-color: rgb(235, 235, 235);
                border-radius: 20px;
         
              }
            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                border-radius: 20px;
              }

            &::-webkit-scrollbar {
                height: 5px;
              }
              
              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
              }
              
              &::-webkit-scrollbar-thumb {
                height: 5px;
                background-color: darkgrey;
              }
       
        }
        &-search{
            text-align: start;
            margin-bottom: 15px;
            .search{
                padding: 9.5px 13px;
                border: 1px solid #E8E8E8;
                border-radius: 8px;
            }
            .search-input{
                justify-content: flex-start;
            }
        }
    }

}

.paggination{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}