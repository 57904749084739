.customBlock{
    &-block{
        background-color:  white;
        border: 1px solid #DEDEDE;
        border-radius: 8px;
    }
}

.uploadBlock{
    input{
        display: none;
    }
    &-container{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px;
        text-align: center;
            cursor: pointer;
            &-content{
                &-title{
                    font-weight: 500;
                    font-size: 16px;
                }
                &-description{
                    font-weight: 400;
                    font-size: 14px;
                    color: #91939A;
                    img{
                        max-width: 50%;
                        max-height: 50%;
                    }
                }
            }
        
    }
}

.dnd{
    &-content{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 25px;
    }
}

.customBlockContent{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.richText-content{
    width: calc(50% - 12.5px);
    .label{
        font-weight: 400;
        font-size: 14px;
    }
}

.TrueFalseConatiner{
    &-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 15px;
        .true ,.false{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                padding: 17px 0;
                width: 70%;
                border: 1px solid #DEDEDE;
                border-radius: 8px;
                text-align: center;
                font-weight: 500;
                font-size: 19px;
                color: #1CBE2B;
            }
            .label{
                font-weight: 400;
                font-size: 14px;
            }
        }
        .false{
            p{
                color: #FF613D;
            }
        }         
            
        
    }
}

.radio{
    cursor: pointer; 
    user-select:none;
    input{
      display: none;
      &+span{
        position: relative;
        padding-left: 30px;
        &:before{
          content: '';
          display: block; 
          position: absolute;
          top: 2px;
          left: 0;
          border-radius: 50%;
          margin-right: 5px;
          width: 16px;
          height: 16px;
          border: 1px solid #ccc;
          background: #fff;
        }
        &:after{
          content: '';
          display: block; 
          width: 10px;
          height: 10px;
          background: #222;
          position: absolute;
          border-radius: 50%;
          top: 6px;
          left: 4px;
          opacity: 0;
          transform: scale(0,0); 
          transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
        }
      }
      &:checked+span:after{
        opacity: 1;
        transform: scale(1,1);
      }
    }
  }

.container-radio{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    &-content{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
}

.addOption{
    cursor: pointer;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    color: #4955C0;
}

.rich{
    width: 70%;
}

#hidden{
    position: relative;
    &::before{
        content: "";
        position: absolute;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.304);
        z-index: 10;
        top: 0;
        left: 0;
    }
}

.words{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 28px;
}

.choices{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 23px;
}


.richTextMultiple{
    &-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &-content{
        padding: 15px 0;
        width: 45%;
        position: relative;
        &_delete{
            position: absolute;
            top: 15px;
            right: 5px;
            color: red;
            cursor: pointer;
            font-size: 14px;
        }
    }
}


#border{
    &::after{
        content: ""; 
        position: absolute;
        bottom: 0;
        left: 0;
        width: 222%;
        height: 1px;
        background-color: #DEDEDE;
    }
}


.phraseBuilder{
    &-container{
        padding-bottom: 15px;
        border-bottom: 1px solid #DEDEDE;
    }
}

.person{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 10px;
    .uploadBlock-container {
        height: 136px;
    }

}

.speech{
    padding-bottom: 15px;
    border-bottom: 1px solid #DEDEDE;
}

.speech-container{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .border{
        padding: 5px;
        border: 1px solid #939393;
        border-radius: 8px;
        
    }
}

.speechs{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    p:first-child{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
            color: red;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
        }
    }
    .addOption{
        margin-top: 15px;
    }
    &-words{
        padding-bottom: 15px;
        border-bottom: 1px solid #DEDEDE;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
    &-choices{
        margin-top: 15px;
    }
}

.correctAnswers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    &-input{
        width: calc(50% - 10px) ;
    }
}

.BlockError{
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
}