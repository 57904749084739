.inputArray{
    width: 100%;
    fieldset{
        border: none;
    }
    .button-container{
        display: flex;
        justify-content: center;
        align-items: center;
      
    }
}