.form-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    .customInput{
        width: 50%;
    }
}

.customForm{
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 3px;
        background-color: rgb(235, 235, 235);
        border-radius: 20px;
 
      }
    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 20px;
      }
    form{
        display: flex;
        height: 100%;
        justify-content: space-between;
        flex-direction: column;
    }
}

.country{
    img{
        width: 25px;
        height: 25px;
    }
}

.row-us{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    .label{
        color: #898989;
        margin: 0;
        font-weight: 400;
        font-size: 15px;
    }
    .content{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        img{
            max-height: 300px;
        }
    }
}