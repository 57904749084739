.questionblock{
    cursor: pointer;
    &-icon{
        display: flex;
        margin-bottom: 10px;
    }
    &-title{
        font-weight: 400;
        font-size: 16px;
        p{
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}