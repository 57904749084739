.usersList{
    height: calc(100% - 145px);
    padding: 26px 20px;
    &__serachBar{
        text-align: start;
        .search{
            padding: 9.5px 13px;
            border: 1px solid #E8E8E8;
            border-radius: 8px;
        }
        .search-input{
            justify-content: flex-start;
        }
    }
    &__table{
        margin-top: 15px;
        height: calc(100% - 98px);
        overflow-y: scroll;
        // &::-webkit-scrollbar{
        //     display: none;
        // }
         &::-webkit-scrollbar {
                        width: 3px;
                        background-color: rgb(235, 235, 235);
                        border-radius: 20px;
                 
                      }
                    &::-webkit-scrollbar-thumb {
                        background-color: darkgrey;
                        border-radius: 20px;
                      }
        // -ms-overflow-style: none;
        // scrollbar-width: none; 
    }
}