.popup {
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: -100%;
    left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    font-weight: 600;
    font-size: 20px;
    box-shadow: 0px 0px 3px #eee;
    transition: all 0.5s;
    animation: show 2s linear;
     
  &__response {
    padding: 20px;
    p{
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
  &__response img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(118deg)
      brightness(103%) contrast(104%);
  }
  &__message {
    background-color: white;
    padding: 20px;
  }
  
  .mediumSizeImg {
    width: 25px;
    height: 25px;
  }
  }
  
  @keyframes show {
    0% {
      left: -50%;
    }
    25% {
      left: 5px;
    }
    50% {
      left: 5px;
    }
    75% {
      left: 5px;
    }
    100% {
      left: -100%;
    }
  }
 
  