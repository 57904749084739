@forward "./components/index";
.phone{
    border: 1px solid #DEDEDE;
    border-radius: 15px;
    width: 320px;
    height: 650px;
    background-color: white;
    margin: 5px auto 0;
    padding: 0 20px 10px;
    &__nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_content{
        height: calc(100% - 51px);
        &-question-type{
            text-align: center;
            margin-bottom: 15px;
            p{
                color: #3DB9FF;
                border: 1px solid #3DB9FF;
                border-radius: 27px;
                padding: 6.5px 26px;
                width: fit-content;
                font-weight: 700;
                font-size: 16px;
                margin: 0 auto;
            }
        }
        &-blocks{
            height: calc(100% - 60px);
            overflow-y: scroll;
            &::-webkit-scrollbar {
               display: none;
              }

            -ms-overflow-style: none;
            scrollbar-width: none;   
        }
    }
}

.smartphone {
    position: relative;
    width: 360px;
    height: 640px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
  }
  
  /* The horizontal line on the top of the device */
  .smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }
  
  /* The circle on the bottom of the device */
  .smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }
  
  /* The screen (or content) of the device */
