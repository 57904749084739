.library__content-library{
    &-back{
        font-weight: 400;
        font-size: 15px;
        cursor: pointer;
        color: #FF613D;
        span{
            font-weight: bold;
        }
    }
    &-search{
        position: relative;
    }
    &-buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .paggination{
            width: fit-content !important;
        }
        padding-top: 7px;
        &-content {
            display: flex;
            align-items: center;
            column-gap: 13px;
            div{
                border-radius: 8px;
                font-size: 16px;
                cursor: pointer;
            }
        }
        &-content-cancel{
            color: #91939A;
            border: 1px solid #E8E8E8;
            padding: 10px 50px;
            font-weight: 400; 
        }
        &-content-upload{
            background: #4955C0; 
            color: white;
            font-weight: 500;
            padding: 10px 67px;
        }
    }
    &-folders{
        height: calc(100% - 165px);
        &-content{
            &-photo-item {
                position: relative;
                overflow: hidden;
                cursor: pointer;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                filter: grayscale(40%);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                .more-container{
                    transform: translate(0);
                    top: 45px;
                    bottom: auto;
                }
                span{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                    background-color: white;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    line-height: 47px;
                    img{
                        transform: rotate(90deg);
                    }
                } 
                &--name{
                    color: white;
                    font-weight: 500;
                    font-size: 17px;
                }
                &--date{
                    color: white;
                    font-weight: 400;
                    font-size: 15px;
                    color: #ABABAB;                    
                }
                &--checked{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    // background-color: rgba(128, 128, 128, 0.567);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0 4px 5px rgb(0 0 0 / 30%);
                    border: 3px solid #3448c5;
                    img{
                        filter: invert(17%) sepia(78%) saturate(3286%) hue-rotate(231deg) brightness(99%) contrast(85%);
                        width: 100px;
                    }
                }
            }
        }
    }
}

#disfilter{
    filter: grayscale(0%);
}