.loadingView{
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading{
   width: 100%;
   height: calc(100% - 145px);
   position: relative;
}

#circle2 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 4px black solid;
    border-radius: 50%;
    -webkit-animation: spin2 1s infinite linear;
            animation: spin2 1s infinite linear;
  }
  
  @-webkit-keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes spin2 {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  .spinner{
    height: calc(100vh + 145px);
  }