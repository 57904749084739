.fillInTheBlanks{
    margin: 20px 0;
    &_words{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-content{
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            column-gap: 10px;
            p{
                width: fit-content;
                font-weight: 500;
                font-size: 17px;
            }
            .hidden{
                width: 60px;
                height: 25px;
                border-bottom: 1px solid #DEDEDE;
            }
            margin-bottom: 50px;
        }
        &-answers{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap :10px;
            .word{
                width: fit-content;
                padding: 10px;
                background: #FFFFFF;
                border: 1px solid #E8E8E8;
                box-shadow: 0px 3px 0px #E8E8E8;
                border-radius: 10px;
                font-weight: 500;
                font-size: 17px;   
                cursor: pointer; 
            }
        }
    }
}