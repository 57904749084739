.role-input_container {
    display: flex;
    padding: 1rem 2rem 2rem 1.4rem;
    align-items: center;
    justify-content: space-between;

    &>div {
        flex: 1 0 60%;
    }

    & div:last-of-type {
        flex: 1 1 20%;
        margin: unset;
        margin-top: auto;
        padding-right: unset;


        button {
            height: 60px;
            background-color: #4955C0;
            border: 1px solid #fff;
            transition: all ease-in-out;

            p { 
                color: #fff;
            }
        }
    }

    border-bottom: 1px solid #E8E8E8;
}