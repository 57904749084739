.button-form{
    width: 100%;
    padding: 19px 29px 0;
    .submitButton{
        border: 1px solid #FF613D;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14.5px;
        width: 100%;
        background-color: white;
        font-family: inherit;
        cursor: pointer;
        p{
            font-weight: 500;
            font-size: 15px;
            color: #FF613D;
            text-transform: capitalize;
        }
}
}

