.questionBlock{
    &_menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 10px;
        background-color: white;
        border: 1px solid #DEDEDE;
        border-radius: 7px;
        margin-bottom: 16px;
        position: relative;
        z-index: 9;
        .order{
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 13px;
            column-gap: 15px;
            p:nth-child(2){
                border: 1px solid #DEDEDE;
                border-radius: 6px;
                padding: 2px 10px;
            }
            p:first-child{
                cursor: pointer;
                color: #FF613D;
                &:hover{
                    text-decoration: underline;
                }
            }
            p:last-child{
                cursor: pointer;
                color: #4955C0;
                &:hover{
                    text-decoration: underline;
                }
            }
            
        }

        .title{
            font-weight: 500;
            font-size: 14px;
            color: #FF613D;
            user-select: none;
        }
        &-control{
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 27px;
            p{
                position: relative;
                font-weight: 400;
                font-size: 14px;
                color:  #E70013;
                cursor: pointer;
                user-select: none;
                z-index: 10;
            }
            img{
                transition: all .2s;
                user-select: none;
                cursor: pointer;
            }
            .rotate{
                transform: rotate(180deg);
            }
        }
    }
    &-content{
        height: 102px;
        background-color:  white;
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        
    }
    #hideBlock{
        height: 0;
        padding: 0;
        overflow: hidden;
        border: none;
    }
    .errroBlock{
        color: red;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        margin-top: 5px;
        margin-left: 10px;
    }
}