.duplicate-form {

    h5 {
        font-weight: 400;
        font-size: 15px;
        color: #898989;

        .lesson-title {
            padding-left: 6px;
            color: #121212
        }
    }

}