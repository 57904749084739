.tipBlock{
    &-container{
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        gap: 0;
        &-item{
            border: none;
        }

    }
}