@forward "./components/index";

@mixin activeItem {
        font-weight: 500;
        color: #4955C0;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -25px;
            left: 0;
            background-color: #4955C0;

        }
}

.library{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    background-color: white;
    z-index: 100;
    background: #FFFFFF;
    border-radius: 12px;
    width: 80%;
    height: 90%;
    &__header{
        padding: 24px;
        border-bottom: 1px solid #DEDEDE;
        position: relative;
        &-exit{
            position: absolute;
            top: 15px;
            right: 25px;
            cursor: pointer;
        }
        ul{
            display: flex;
            align-items: center;
            column-gap: 50px;
            li{
                font-weight: 400;
                font-size: 18px;
                color: #91939A;
                cursor: pointer;
                &:hover{
                    @include activeItem
                }
            }
            .active{
                @include activeItem
            }
        }
    }
    &__content{
        padding: 25px;
        height: calc(100% - 76px);
    }
}

