.positionSelect{
    width: 100%;
    &-content{
        background-color: white;
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        width: 100%;
        margin-top: 10px;
        position: relative;
        overflow: hidden;
        &_header{
            background-color: #F7F7F7;
            padding: 9px 10px;
            font-weight: 400;
            font-size: 14px;
        }
        &_body{
            padding: 10px;
            height: 66px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .options{
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        background-color: white;
        border: 1px solid #dedede82;
        transform: translateY(100%);
        border-radius: 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
        padding: 15px 0;
        p{
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            padding: 0 15px;
            width: 100%;
            &:hover{
                background-color: #eee;
            }
        }
    }
}

.customBlockComponent{
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    background-color: white;
}

.rishText{
    &-controll{
        position: relative;
        span.title{
            color: red;
            // position: absolute;
            font-size: 14px;
            font-weight: 400;
            right: 10px;
            top: 50%;
            // transform: translateY(-50%);
            cursor: pointer;
        }
    }
    .text{
        width: 100%;
        height: 100px;
        padding: 10px;
        overflow-y: scroll;
        &:focus{
            outline: none;
            border: none;
        }
    }
}

.rishTextControll{
    padding: 2px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5px;
    &-content{
        display: flex;
        align-items: center;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 4px;
            background-color: rgb(235, 235, 235);
            border-radius: 20px;
          }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 20px;
          }
        column-gap: 15px;
    }
    background: #F7F7F7;
    border-radius: 7px 7px 0px 0px;
    span{
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            background-color: #eee;
        }
       
    }
    .colors{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px; 
        .color{
            cursor: pointer;
            width: 20px !important;
            height: 20px !important;
            min-width: 20px !important;
            min-height: 20px !important;
            border: 1px solid #21252954;
            border-radius: 4px;
            img{
               opacity: 0;
            }
        }
    }

    
    &_text{
        position: relative;
        .textAlign{
            img{
                width: 15px;
                height: 15px;
            }
        }
        .aligns{
            display: flex;
            align-items: center;
            column-gap: 10px;
            .align{
                padding: 5px;
                cursor: pointer;
                display: flex;
                align-items: center;
                &:hover{
                    background-color: #eee;
                }
                img{
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}

.bold{
    font-weight: bold;
}

.sp{
    width: 100px;
    height: 100px;
    background-color: red;
}
.multiple{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    p{
        color: white;
        height: fit-content;
        font-weight: 400;
        font-size: 14px;
        padding: 2px 10px;
        background: #FF613D;
        border-radius: 5px;
    }
}

.richTextsection{
    min-width: 70%;
}

#center{
    text-align: center;
}
#right{
    text-align: right;
}
#left{
    text-align: left;
}