@mixin activeLink {
    background: #4955C0;
    border-radius: 8px;
    span{
         color: white;
    }
    .link .icon-link img{
        filter: none;
   }
   .subLinks{
       img{
        filter: none;
       }
   }
}



li{
    .link-container{
        padding: 10px;
        color: #91939A;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .link{
            display: flex;
            align-items: center;
            column-gap: 15px;
            font-weight: 500;
            font-size: 16px;
            .icon-link img{
                filter: invert(62%) sepia(13%) saturate(145%) hue-rotate(189deg) brightness(93%) contrast(86%);
            }
        }
        .subLinks{
            img{
                filter: invert(62%) sepia(13%) saturate(145%) hue-rotate(189deg) brightness(93%) contrast(86%);
            }
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .icon-link{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:hover{
            @include activeLink;
        }
    }
    .activeLink{
        .link-container{
        @include activeLink;
    }
    }
    .activeRoute{
        @include activeLink;
    }
}

                  
.subLinks-conainer{
    display: flex;
    flex-direction: column;
    padding-left: 55px;
    row-gap: 6px;
    margin-top: 6px;
    &_subLink{
        cursor: pointer;
        color: #91939A;
        font-weight: 400;
        font-size: 16px;
        padding: 8px 0;
        position: relative;
        text-transform: capitalize;
        &::before{
            content: "";
            width: 6px;
            height: 6px;
            position: absolute;
            border-radius: 50%;
            background-color: #91939A;
            left: -16px;
            top: 50%;
            transform: translateY(-50%);
        }
        &:hover{
            &::before{
                background-color: #4955C0;
            }
            color: #4955C0;
            font-weight: 500;
        }
        
    }
}
.activeSubLink{
    span{
        &::before{
            background-color: #4955C0;
        }
        color: #4955C0;
        font-weight: 500;
    }
    &:before{
        background-color: #4955C0;
    }
}
.openLink{
    transform: rotate(180deg);
}