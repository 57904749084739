.menubar{
    padding: 17px 23px 13px 20px;
    border-bottom:1px solid #E8E8E8;
    &__header{
        margin-bottom: 4px;
        p{
            font-weight: 400;
            font-size: 15px;
            color: #91939A;
        }
    }
    &__title-buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
            font-weight: 500;
            font-size: 24px;
        }
    }
}