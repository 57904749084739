.secondDialogBlock{
    .dialog{
        display: flex;
        align-items: center;
        width: 100%;
        &-text{
            display: flex;
            align-items: center;
            background: #E4E6F6;
            border-radius: 10px;        
            font-weight: 500;
            font-size: 15px;  
            padding: 12px 20px;      
            width: 100%;  
            min-height: 44px;
        }
    }
    .conversationBlock{
        &_answers{
            height: 35%;
        }
        &_conversation{
        height: 55%;
        &-dialog{
            align-items: center;
            column-gap: 6px;
            }
        }
    }
}