.customInput{
    .label {
        display: block;
        font-weight: 400;
        font-size: 15px;
        color: #898989;
        margin-bottom: 10px;
        span{
            color: red;
        }
    }
  
  
   input:not(.imageName) {
    width: 100%;
    height: 60px;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 10px;
    font-family: inherit;
    &::placeholder{
        font-weight: 400;
        font-size: 15px;
        color: #C9C9C9;
    }
  }
  
  .customcontainer{
    width: 100%;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 10px;
    font-family: inherit;
    height: 60px;
  }

   input:focus {
    outline: none;
  }
  
  .is-invalid {
    border-color: red  !important;
  }
  
  .error {
    position: absolute;
    font-size: 10px;
    color: red;
    bottom: -17px;
    left: 4px;
  }
}

