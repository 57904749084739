.notes{
    position: absolute;
    background-color: red;
    z-index: 16;
    bottom: 15px;
    right: 15px;
    height: calc(100vh - 10%);
    width: 412px;
    background: #FFF6E6;
    border-right: 1px solid #E8E8E8;
    border-radius: 12px;
    overflow: hidden;
    
    textarea{
        width: 100%;
        height: 100%;
        background-color: #FFF6E6;
        padding: 10px;
        font-family: inherit;
        font-size: 16px;
        font-weight: 400;
        border: none;
        resize: none;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: rgb(235, 235, 235);
            border-radius: 20px;
     
          }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 20px;
          }
        &:focus{
            outline: none;
        }
    }
}