@forward  "./components/index";


.chapter{
    height: calc(100% - 69px);
    .chapter__container{
        padding: 34px 19px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 20px;
        &-items{
        }
        max-height: calc(100% - 103px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: rgb(235, 235, 235);
            border-radius: 20px;
     
          }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 20px;
          }
    }
    .chapter_item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 258px;
        height: 281px;
        min-width: 258px;
        min-height: 281px;
        border: 1px solid #DEDEDE;
        border-radius: 8px;
        padding: 18px 18px 23px;
        &-header{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
            &-more{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .more-container{
                    left: -6px;
                    bottom: -8px;
                }
                    &>img{
                        transform: rotate(90deg);
                        cursor: pointer;
                    }
               

            }
            &-status{
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                column-gap: 8px;
                color: #1CBE2B;
                padding: 7px 12px;
                border: 1px solid #1CBE2B;
                border-radius: 22px;
            }
            #status-unlock{
                color: #FFB448;
                border: 1px solid #FFB448;
            }
            &-icon{
                width: 68px;
                height: 68px;
                border-radius: 8px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &-description{
            text-align: right;
            &-title{
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 10px;
            }
            &-content{
                font-weight: 400;
                font-size: 15px;
                color: #91939A;
            }
        }
        &-button{
            .button-form{
                padding: 0;
            }
            button{
                padding: 10px;
            }
        }
    }
    .add-chapter{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .2s;
        &:hover{
            background-color: #eeeeee4f;
        }
        &-content{
            text-align: center;
            img{
                filter: invert(83%) sepia(0%) saturate(0%) hue-rotate(181deg) brightness(94%) contrast(95%);
                height: 26.5px;
            }
            p{
                font-weight: 400;
                font-size: 16px;
                color: #C4C4C4;
            }
        } 
    }
}