.conversationBlock{
    display: flex;
    height: 100%;
    flex-direction: column;
    &_conversation{
        height: 70%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        overflow-y: scroll;
        padding-bottom: 10px;
        &::-webkit-scrollbar {
           display: none;
          }
        -ms-overflow-style: none;
        scrollbar-width: none;   
       
        &-dialog{
            display: flex;
            justify-content: space-between;
            .avatar{
                border-radius: 50%;
            }
            &-content{
                position: relative;
                    &::before{
                        content: url("./../../../../../../assets/icons/Polygon\ 2.png");
                        width: 13px;
                        height: 13px;
                        // border: 10px solid transparent;
                        // border-right-color: black ;
                        position: absolute;
                        top: 15px;
                        left: -12px;
                    }
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                background: #FFFFFF;
                border: 1px solid #DEDEDE;
                border-radius: 7px;  
                padding-top: 11px ;
                
                width: 75%;              
                .hedaer{
                    padding: 0 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    audio{
                        display: none;
                    }
                    span{
                        font-weight: 500;
                        font-size: 12px;                        
                    }
                    span:not(.name){
                        cursor: pointer;
                    }
                }
                .content{
                    
                    padding: 0 14px;
                    font-weight: 500;
                    font-size: 15px;  
                         display: flex;
                         align-items: center;
                         flex-wrap: wrap;
                         gap: 7px;             
                    .hidden{
                        width: 40px;
                        height: 25px;
                        border-bottom: 1px solid #DEDEDE;
                        display: block;
                    }
                }
                .translate{
                    // padding: 7px 0;
                    text-align: right;
                    border-top: 1px solid #DEDEDE;
                    padding: 7px 14px;
                }
            }
        }
    }
    &_answers{
        height: 25%;
        border-top: 1px solid #DEDEDE;
        padding: 25px 13px;
        overflow-y: scroll;
        width: 100%;
        &::-webkit-scrollbar {
            width: 3px;
            background-color: rgb(235, 235, 235);
            border-radius: 20px;
     
          }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 20px;
          }
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
         .word{
            width: fit-content;
            padding: 10px;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            box-shadow: 0px 3px 0px #E8E8E8;
            border-radius: 10px;
            font-weight: 500;
            font-size: 15px;  
            height: fit-content; 
            cursor: pointer; 
         } 
    }
}

.rtl{
    direction: rtl;
    ::before{
        transform: rotate(180deg);
        right: -13px;
    }
    .hedaer{
        direction: ltr;
    }
    .content{
        direction: ltr;
    }
    .dialog-text{
        direction: ltr;
    }
}