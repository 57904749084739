.library__content-library{
    height: 100%;
    &-search{
        display: flex;
        align-items: center;
        column-gap: 28px;
        p{
            font-weight: 600;
            font-size: 16px;
            color: black;                    
        }
        .search{
            width: fit-content;
            padding: 9.5px 13px;
            border: 1px solid #E8E8E8;
            border-radius: 8px;
        }
    }
    &-folders{
        height: calc(100% - 98px);
        margin-top: 52px;
        overflow: auto;
        &-content{      
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 11px;
            overflow: auto;
            &-item{
                position: relative;
                width: 220px;
                height: 200px;
                border-radius: 8px;      
                padding: 13px 16px;   
                text-align: center;     
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border: 1px solid #DEDEDE;
                align-items: center; 
                .more-container{
                    transform: translate(0);
                    top: 45px;
                    bottom: auto;
                }
                span{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                    img{
                        transform: rotate(90deg);
                    }
                } 
                &-frs{
                    p{
                        font-weight: 600;
                        font-size: 16px;
                        margin-top: 10px;
                    }
                }   
                &-scs{
                    width: 100%;
                    &-button{
                        .button-form{
                            padding: 0 !important;
                        }
                        button{
                            padding: 10px !important;
                        } 
                    }
                }
            }
        }
    }
}