.globalColumnControll{
    width: 275px;
    background-color: white;
    position: absolute;
    bottom: -5px;
    right: 0;
    transform: translateY(100%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    padding: 15px;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    max-height: calc(100vh - 240px);
    overflow: auto;
    &::-webkit-scrollbar {
        width: 3px;
        background-color: rgb(235, 235, 235);
        border-radius: 20px;
 
      }
    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 20px;
      }
}

input[type="checkbox"].switch_1{
	font-size: 30px;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	width: 40px;
	height: 20px;
	background: #ddd;
	border-radius: 3em;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_1:checked{
	background: #0ebeff;
  }
  
  input[type="checkbox"].switch_1:after{
	position: absolute;
	content: "";
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #fff;
	-webkit-box-shadow: 0 0 .25em rgba(0,0,0,.3);
	        box-shadow: 0 0 .25em rgba(0,0,0,.3);
	-webkit-transform: scale(.7);
	        transform: scale(.7);
	left: 0;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
  }
  
  input[type="checkbox"].switch_1:checked:after{
	left: calc(100% - 20px);
  }

  .column{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-row{
        display: flex;
        align-items: center;
    }
  }