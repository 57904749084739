.global__filter{
    width: 275px;
    background-color: white;
    position: absolute;
    bottom: -5px;
    right: 0;
    transform: translateY(100%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 15px;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    max-height: calc(100vh - 240px);
    overflow: auto;
    &::-webkit-scrollbar {
        width: 3px;
        background-color: rgb(235, 235, 235);
        border-radius: 20px;
 
      }
    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 20px;
      }
    .label{
        & + input{
            margin: 0;
        }
        font-size: 12px;
        font-weight: 400;
        color: black;
        margin: 0;
        margin-top: 5px;
    }
    &-container{
        &-field{
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 8px;
            border-radius: 2px;
            border: 1px solid #E4ECF2;
            background-color: #F5F7FA;
            font-weight: 500;
            font-size: 15px;
            .downIcon{
                width: 20px;
                height: 20px;
                
            }
            &-content{
                height: 0;
                padding: 0;
                overflow: hidden;
                transition: all .2s;
            }
           
        }
    }
    .filterInputText{
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        width: 100%;
        padding: 10px;
        margin-top: 6px;
        &:focus{
            outline: none;
        }
    }
    .filterSelect{
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        width: 100%;
        padding: 10px;
        margin-top: 6px;
    }
}

.FilterOpen{
    height: auto !important;
    overflow: visible;
}