.paggination{
    &__limit{
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        padding: 10px;
        font-family: inherit;
        margin-right: 10px;
        &:focus{
            outline: none;
        }
    }
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    align-items: center;
    padding-right: 20px;
}