.imageBlock{
    height: 180px;
    width: 100%;
    background: #E0E0E0;
    border-radius: 7px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
    }
}