.DialogBuilderBlock{
    .text1{
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        word-wrap: break-word;
        max-width: 80%;
        margin: 0 auto;
    }
    .DialogBuilderBlock-container{
        margin-top: 29px;
       
        &-content{
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            .speeche{
                background: #FFFFFF;
                border: 1px solid #E8E8E8;
                box-shadow: 0px 2px 0px #E8E8E8;
                border-radius: 10px;
                display: flex;
                align-items: center;
                padding: 5px;
                column-gap: 15px;
               
            }
        }

    }
}

.avatar{
    width: 41px;
    height: 41px;
    border-radius: 6px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
    }    
}