.search{
    &-input{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 13px;
        input{
            width: 300px;
            font-family: inherit;
            border: none;
            font-weight: 500;
            font-size: 16px;
            &:focus{
                outline: none;
            }
        }
    }
}