.dndBlock{
    margin-top: 20px;
    &_questions{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        row-gap: 20px;
        &-item{
            &-question{
                padding: 13px ;
            }
            background-color: white;
            box-shadow: inset 0px 2px 7px 5px rgba(0, 0, 0, 0.06);
            border-radius: 7px;
            overflow: hidden;
            text-align: center;
            &-content{
                background: #F1EFFB;
                padding: 15px;
                p{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 8.27px;
                    color: rgba(83, 80, 249, 0.47); 
                    font-weight: 500;
                    font-size: 15px;               
                }
            }
        }
    }
    &_answers{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        &-item{
            padding: 25px;
            color: white;
            font-weight: 700;
            font-size: 15px;
            background: #4955C0;
            border-radius: 7px;
            text-align: center;
            cursor: pointer;
        }
    }
}