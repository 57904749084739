.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 40px;
    border-bottom: 1px solid #E8E8E8;
    .backscreen{
        background-color: transparent;
    }
    &__user{
        display: flex;
        align-items: center;
        column-gap: 14.5px;
        &-info{
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 14px;
            padding-left: 20px;
            border-left: 1px solid #E8E8E8;
            &-name{
                .name{
                    font-weight: 500;
                    font-size: 14px;
                }
                .role{
                    font-weight: 400;
                    font-size: 14px;
                    color: #91939A;
                }
            }
            .logout{
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 16;
                transform: translateY(100%);
                background: #FFFFFF;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                padding: 19px 23px;
                &-content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 15px;
                    cursor: pointer;
                    font-weight: 400;
                    font-size: 16px;
                    color: #91939A;                    
                }
            }
            &-image{
                width: 34px;
                height: 34px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #E5E5E5;
                padding: 4px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &-notification{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            transition: all .2s;
            cursor: pointer;
            &:hover{
                background: #E5E5E5;
            }
        }
    }
}