.trueFalseBlock{
    p{
        text-align: right;
    }
    &-text1{
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 5px;
    }
    &-text2{
        font-weight: 500;
        font-size: 15px;
        color: #969696;
    }
    .trueFalse{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        p{
            cursor: pointer;
            font-weight: 500;
            font-size: 17px;
            padding: 18.5px ; 
            width: 158px;
            text-align: center;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            box-shadow: 0px 3px 0px #E8E8E8;
            border-radius: 10px;
        }
               
    }
}