.notFoundView{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p{
        font-size: 35px;
        font-weight: 600;
        margin-top: -25px;
        letter-spacing: 2px;
        margin-bottom: 5px;
    }
    span{
        color: #95A6B1;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 1px;
        text-align: center;
    }
    .backHome-button{
        border-radius: 8px;
        background-color: #1D2084;
        color: white;
        text-align: center;
        margin: 0 auto;
        padding: 10px 30px;
        cursor: pointer;
        font-size: 15px;
        letter-spacing: 1px;
        font-weight: 400;
        margin-top: 25px;
        border: 1px solid transparent;
        transition: all .2s;
        &:hover{
            background-color: white;
            color: #1D2084;
            border-color:#1D2084;
        }
    }
}