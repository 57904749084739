@forward "./inputText/inputText";
@forward "./inputSelect/inputSelect";
@forward "./inputUpload/inputUpload";
@forward "./inputArrayText/inputArrayText.scss";

.resize{
    height: 100%;
    margin: 0;
    & input{
        border: none !important;
        height: 70% !important;
    }
}

.customInput{
    position: relative;
}