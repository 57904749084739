@forward "./components/index";
.question{

    height: calc(100% - 69px);
    &__container{
        .question-button{
            background: #4955C0;
            border: 1px solid #4955C0;
            border-radius: 8px;      
            padding: 12.5px;
            font-weight: 500;
            font-size: 16px;  
            color: white;  
            cursor: pointer;  
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(100% - 105px);
        &-questionblock{
            padding: 19px;
            width: 25%;
            height: 100%;
            .search{
                padding: 9.5px 13px;
                border: 1px solid #E8E8E8;
                border-radius: 8px;
                input{
                    width: 100%;
                }
            }
            &-content{
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                gap: 32px 14px;
                max-height: calc(100% - 61px);
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 3px;
                    background-color: rgb(235, 235, 235);
                    border-radius: 20px;
                  }
                &::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    border-radius: 20px;
                  }
            }
        }
        &-forms-phonePreview{
            width: 49%; 
            height: 100%;
            background-color: #F9FBFC;
            border-left: 1px solid #E8E8E8;
            border-right: 1px solid #E8E8E8;
            padding: 15px 20px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 3px;
                background-color: rgb(235, 235, 235);
                border-radius: 20px;
         
              }
            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                border-radius: 20px;
              }
        }
        &-forms{
            display: flex;
            flex-direction: column;
            row-gap: 50px;
            height: 100%;
        }
        &-phoneReview{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
        &-notes{
            width: 26%;
            min-width: 380px;
            height: 100%;
            padding: 25px 10px;
            background-color: #FFFAF0;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
                background-color: rgb(235, 235, 235);
                border-radius: 20px;
         
              }
            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                border-radius: 20px;
              }
            .notes{
                height: calc(100% - 34px);
                padding: 5px;
                border: none;
                &:focus{
                    outline: none;
                }
            }
        }
    }
}